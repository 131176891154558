import React from 'react';
import { device } from '../Device'
import styled from 'styled-components';

import Image1 from '../../images/gallery/1sala_casamento.jpeg'
import Image2 from '../../images/gallery/2sala_casamento.jpeg'
import Image3 from '../../images/gallery/3sala_casamento.jpeg'
import Image4 from '../../images/gallery/4sala_casamento.jpeg'
import Image5 from '../../images/gallery/5sala_casamento.jpeg'
import Image6 from '../../images/gallery/6sala_casamento.jpeg'
import Image7 from '../../images/gallery/7sala_casamento.jpeg'
import Image8 from '../../images/gallery/8sala_casamento.jpeg'
import Image9 from '../../images/gallery/9sala_casamento.jpeg'
import Image10 from '../../images/gallery/10sala_casamento.jpeg'
import Image11 from '../../images/gallery/11sala_casamento.jpeg'
import Image12 from '../../images/gallery/12sala_casamento.jpeg'
import Image13 from '../../images/gallery/13sala_casamento.jpeg'
import Image14 from '../../images/gallery/14sala_casamento.jpeg'
import Image15 from '../../images/gallery/15sala_casamento.jpeg'
import Image16 from '../../images/gallery/16sala_casamento.jpeg'
import Image17 from '../../images/gallery/17sala_casamento.jpeg'

import "react-image-gallery/styles/css/image-gallery.css"
import ImageGallery from 'react-image-gallery';

const Background = styled.div`
   background-color: #212121;
 `

const Container = styled.div`
     margin: auto;
     width: 1024px;
     padding: 140px 0;
     @media ${device.mobileL} {
         width: 314px;
     }
     @media (max-width: 1040px) {
       width: 768px;
   }
   @media (max-width: 787px) {
       width: 425px;
   }
   @media ${device.mobileL} {
       width: 314px;
   }
 `

const Image = styled.img`
     width: auto;
     height: 500px;
 `

const Title = styled.h1`
 margin-bottom: 100px;
 font-family: Playfair Display;
 font-style: normal;
 font-weight: bold;
 font-size: 40px;
 line-height: 53px;
 text-align: center;
 letter-spacing: -0.01em;
 /* Core / 0 */
 color: #FFFFFF;
 text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
 `

export default () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17]
  const imageItems = images.map((image) => {
    return {
      original: image,
      thumbnail: image
    }
  })
  
  return (
    <Background>
      <Container>
        <Title>Galeria de fotos</Title>
        <ImageGallery items={imageItems} />;
      </Container>
    </Background>
  );
};
