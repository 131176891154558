import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/ServicePage/Header'
import Body from '../components/ServicePage/Body'
import VideoSection from '../components/ServicePage/VideoSection'
import GallerySection from '../components/ServicePage/CarouselGallery'
import SEO from '../components/SEO'

const ServicePage = (props) => {
    return (
        <Layout>
            <SEO title={props.pageContext.title} />
            <Header pageContext={props.pageContext} />
            <Body
                imageURL={props.pageContext.secondarySection.secondImage.sourceUrl}
                title={props.pageContext.secondarySection.heading}
                content={props.pageContext.secondarySection.content}
                buttonTitle={props.pageContext.secondarySection.buttontext}
                buttonLink={props.pageContext.secondarySection.buttonlink}
            />
            {props.pageContext.video.link &&
            <VideoSection
                title={props.pageContext.video.title}
                subtitle={props.pageContext.video.subtitle}
                link={props.pageContext.video.link} />}
            {props.pageContext.slug === "casamentos" &&
            <GallerySection />}
        </Layout>
    )
}

export default ServicePage