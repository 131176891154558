import React from 'react'
 import styled from "styled-components"
 import { device } from '../Device'
 const Container = styled.div`
     margin: auto;
     width: 1024px;
     display: flex;
     align-items: center;
     padding: 140px 0;
     flex-direction: column;
     @media (max-width: 1040px) {
         width: 768px;
     }
     @media (max-width: 787px) {
         width: 425px;
     }
     @media ${device.mobileL} {
         width: 314px;
     }
 `

 const Title = styled.h1`
   font-family: Playfair Display;
   font-style: normal;
   font-weight: bold;
   font-size: 40px;
   line-height: 53px;
   text-align: center;
   color: #3E3E3E;
 `

 const Subtitle = styled.h3`
 margin-top: 32px;
 font-family: Google Sans;
 font-style: normal;
 font-weight: normal;
 font-size: 14px;
 line-height: 21px;
 /* or 150% */
 text-align: center;
 /* Core / 80 */
 color: #717171;
 `

 const VideoTitle = styled.h4`
 font-family: Google Sans;
 font-style: normal;
 font-weight: 600;
 font-size: 12px;
 line-height: 21px;
 /* identical to box height, or 175% */
 letter-spacing: 0.03em;
 margin-top: 32px;
 /* Core / 80 */
 color: #717171;
 opacity: 0.8;
 `

 const VideoFrame = styled.iframe`
 margin-top: 32px;
 height: auto;
 min-height: 500px;
 width: 100%;
 `

 const VideoSection = (props) => {
   return (
     <Container>
       <Title>{props.title}</Title>
       <Subtitle>{props.subtitle}</Subtitle>
       <VideoTitle>ASSITA AO VÍDEO</VideoTitle>
       <VideoFrame
         src={props.link}
         frameborder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
         allowfullscreen />
     </Container>
   )
 }
 export default VideoSection
