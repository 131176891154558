import React from 'react'
import styled from "styled-components"
import { device } from '../Device'
import { Link } from 'gatsby'
const GreyBackground = styled.div`
  background: #F2F2F2;
  z-index: -5;
`
const Container = styled.div`
    margin: auto;
    width: 1024px;
    z-index: -2;
    display: flex;
    align-items: center;
    padding: 100px 0;
    @media (max-width: 1040px) {
        width: 768px;
    }

    @media (max-width: 787px) {
        width: 425px;
        flex-wrap: wrap;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`

const ResponsiveImage = styled.img`
    max-width: 50%;
    height: auto;
    @media (max-width: 787px) {
      max-width: 100%;
  }

  z-index: 3;

`

const InfoContainer = styled.div`
    padding: 20px;
    @media (max-width: 787px) {
      padding: 0;
      margin-top: 20px;
  }
  z-index: 2;
`

const Title = styled.h2`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  letter-spacing: -0.01em;

  /* Core / 100 */

  color: #3E3E3E;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
  z-index: 1;
`

const ContentDiv = styled.div`
/* P1 - Regular Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* or 150% */
z-index: 1;

/* Core / 100 */

color: #3E3E3E;
margin-top: 20px;
ol, ul {
	list-style-type: circle;
  margin-left: 16px;
}
`

const Button = styled(Link)`
    margin-top: 40px;
    font-family: Google Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #C8625B;
    text-decoration: none;
    border-bottom: solid 1px #C8625B;
    padding-bottom: 7px;
    display: inline-block;
    z-index: 1;
`


const TransformDiv = styled.div`
    background: #FFFFFF;
    opacity: 0.4;
    border-radius: 97px;
    transform: rotate(-70.79deg);
    position: absolute;
    width: 600px;
    height: 605px;
    z-index: -1;
    margin-left: -70px;
    margin-top: -200px;
    
    @media ${device.laptopL} {
      width: 500px;
      height: 500px;
      margin-left: -30px;
      margin-top: -150px;
    }

    @media (max-width: 1145px) {
      width: 460px;
      height: 460px;
    }

    @media ${device.laptop} {
      display: none;
    }
`


const Body = (props) => {
  return (<GreyBackground>
    <Container>
      <ResponsiveImage src={props.imageURL}></ResponsiveImage>
      <InfoContainer>
        <TransformDiv />
        <Title>{props.title}</Title>
        <ContentDiv dangerouslySetInnerHTML={{__html: props.content}} />
        {props.buttonTitle && <Button to={props.buttonLink}>{props.buttonTitle}</Button>}
      </InfoContainer>
    </Container>
  </GreyBackground>
  )
}

export default Body;