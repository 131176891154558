import React from 'react'
import styled from "styled-components"
import { device } from '../Device'

const Container = styled.div`
    margin: auto;
    width: 1024px;
    display: flex;
    align-items: center;

    @media (max-width: 1040px) {
        width: 768px;
    }

    @media (max-width: 787px) {
        width: 425px;
        flex-wrap: wrap;
    }

    @media ${device.mobileL} {
        width: 314px;
    }
`

const Title = styled.h1`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 80px;
  /* identical to box height */

  letter-spacing: -0.01em;

  /* Core / 100 */

  color: #3E3E3E;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);

`

const SubtitleDiv = styled.div`

/* P1 - Regular Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* or 150% */


/* Core / 100 */

color: #3E3E3E;
  @media ${device.tablet} {
    margin-bottom: 20px;
}

ol, ul {
	list-style-type: circle;
  margin-left: 16px;
}
`

const Image = styled.img`
  width: 550px;
  @media (max-width: 1180px) {
    width: 400px;
  }

  @media ${device.mobileL} {
    width: 300px;
}
`

const Header = (props) => {
  return (
    <Container>
      <div>
        <Title>{props.pageContext.title}</Title>
        <SubtitleDiv dangerouslySetInnerHTML={{ __html: props.pageContext.content }} />
      </div>
      <Image src={props.pageContext.bannerImage.image.sourceUrl} />
    </Container>
  )
}

export default Header